import { useFrame } from "@react-three/fiber"
import { RefObject, useEffect, useRef } from "react"
import { Group, Object3D, Raycaster, Vector3 } from "three"
import ControllerModel from "../../assets/models/controller"
import { useSensorStore } from "../../state/stores/sensorStore"
import { degrees_to_radians, lerp } from "../../utils/math"
import { VectorTuple } from "../../utils/vector"

interface ControllerProps {
	start: VectorTuple
	end: VectorTuple
	allowRotation?: boolean
	body: RefObject<Object3D>
}

const Controller = ({
	start,
	end,
	allowRotation = true,
	body,
}: ControllerProps) => {
	const ref = useRef<Group>(null)
	const data = useRef<VectorTuple>([0.5, 0, 0])

	useEffect(() =>
		useSensorStore.subscribe((state) => {
			if (state.data) {
				data.current = [state.data[0], state.data[1], state.data[2]]
			}
		})
	)

	useFrame(() => {
		if (!ref || !ref.current) return

		// raycast
		if (body && body.current && ref && ref.current && data && data.current) {
			const raycaster = new Raycaster()
			raycaster.set(
				new Vector3(
					lerp(end[0], start[0], data.current[0]),
					1,
					lerp(end[2], start[2], data.current[0])
				),
				new Vector3(0, -1, 0)
			)

			const intersects = raycaster
				.intersectObject(body.current, true)
				.sort((a, b) => a.distance - b.distance)

			if (intersects.length > 0) {
				const intersection = intersects[0]
				const point = intersection.point

				ref.current.position.x = point.x
				ref.current.position.y = point.y
				ref.current.position.z = point.z

				if (allowRotation) {
					const beta = Math.atan((end[2] - start[2]) / (end[0] - start[0]))
					ref.current.rotation.y =
						-beta + degrees_to_radians(data.current[1]) + Math.PI / 2
					ref.current.children[0].rotation.x = degrees_to_radians(
						data.current[2]
					)
				} else {
					const beta = Math.atan((end[2] - start[2]) / (end[0] - start[0]))
					ref.current.rotation.y = -beta + Math.PI / 2
					ref.current.children[0].rotation.x = 0
				}
			}
		}
	})

	return <ControllerModel ref={ref} />
}

export default Controller
