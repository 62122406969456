import update from "immutability-helper"
import moment from "moment"
import { nanoid } from "nanoid"
import { parse } from "qs"
import { ChangeEvent, useCallback, useEffect, useState } from "react"
import Datetime from "react-datetime"
import { isBrowser, isFirefox, isIE, isSafari } from "react-device-detect"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { useLocation } from "react-use"
import Button, { ButtonTheme } from "src/components/button/button"
import DndContainer from "src/components/dndContainer/dndContainer"
import Input from "src/components/input/input"
import Number from "src/components/number/number"
import useSWR from "swr"
import AddSchedule from "../../../../components/addSchedule/addSchedule"
import DashboardHeader from "../../../../components/dashboardHeader/dashboardHeader"
import { HasAuth } from "../../../../components/privateRoute/privateRoute"
import ScheduleItem from "../../../../components/scheduleItem/scheduleItem"
import ScheduleItemCaseView from "../../../../components/scheduleItemView/caseView"
import ScheduleItemMediaView from "../../../../components/scheduleItemView/mediaView"
import ScheduleItemPatientView from "../../../../components/scheduleItemView/patientView"
import ScheduleItemQuizView from "../../../../components/scheduleItemView/quizView"
import SegmentedControl from "../../../../components/segmentedControl/segmentedControl"
import useHandleChange from "../../../../hooks/useHandleChange"
import { fetchApiAuthorized } from "../../../../network/fetch"
import { useErrorStore } from "../../../../state/stores/errorStore"
import { formatName } from "../../../../utils/formatName"
import { formatUrl } from "../../../../utils/formatUrl"

const CreateWebinar = ({ auth }: HasAuth) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { id } = useParams() as Record<string, string>
	const { search } = useLocation()
	const [date, setDate] = useHandleChange("")
	const [title, setTitle] = useHandleChange("")

	const [participants, setParticipants] = useState<
		Record<string, unknown>[] | undefined
	>(undefined)
	const [schedule, setSchedule] = useState<Record<string, unknown>[]>([
		{ id: 1, type: "patient" },
		{ id: 2, type: "quiz" },
	])
	const [csvFile, setCsvFile] = useState<File | undefined>(undefined)
	const [dateBorder, setDateBorder] = useState<string | undefined>(undefined)
	const [missingData, setMissingData] = useState<number[]>([])
	const [isEnglish, setIsEnglish] = useState(false)

	const duplicate = useCallback(
		async (id: string) => {
			const webinar = await fetchApiAuthorized(
				`/webinars/${id}`,
				auth.token,
				auth.expiresIn,
				undefined,
				"GET"
			)
			if (webinar) {
				setDate(moment(webinar.date).format("YYYY-MM-DD[T]HH:mm"))
				setTitle(webinar.title ?? "")
				setCsvFile(webinar.csvFile)
				setIsEnglish(webinar.isEnglish)
				setSchedule(
					webinar.editSchedule
						.filter(
							(v: Record<string, unknown>, i: number) =>
								v.type !== "sendoff" && i !== 0
						)
						.map((v: Record<string, unknown>, i: number) => {
							if (v.data) {
								return {
									id: i,
									type: v.type,
									data: (v.data as Record<string, unknown>).id,
								}
							} else {
								return { id: i, type: v.type }
							}
						})
				)
			}
		},
		[auth, setDate, setTitle]
	)

	useEffect(() => {
		if (search) {
			const query = parse(search.replace("?", ""))
			if (query.copy) {
				duplicate(query.copy as string)
			}
		}
	}, [search, duplicate])

	const { data: currentWebinar } = useSWR(
		id ? [`/webinars/${id}`, auth.token, auth.expiresIn] : null,
		fetchApiAuthorized
	)

	useEffect(() => {
		if (currentWebinar) {
			if (id) {
				setDate(moment(currentWebinar.date).format("YYYY-MM-DD[T]HH:mm"))
				setTitle(currentWebinar.title ?? "")
				setParticipants(currentWebinar.participants)
				setCsvFile(currentWebinar.csvFile)
				setIsEnglish(currentWebinar.isEnglish)
			}
			setSchedule(
				currentWebinar.editSchedule
					.filter(
						(v: Record<string, unknown>, i: number) =>
							v.type !== "sendoff" && i !== 0
					)
					.map((v: Record<string, unknown>, i: number) => {
						if (v.data) {
							return {
								id: i,
								type: v.type,
								data: (v.data as Record<string, unknown>).id,
							}
						} else {
							return { id: i, type: v.type }
						}
					})
			)
		}
	}, [currentWebinar, id, search, setDate, setTitle])

	const movePart = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			const dragCard = schedule[dragIndex]
			setSchedule(
				update(schedule, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragCard],
					],
				})
			)
		},
		[schedule]
	)

	const showOldPicker = (isSafari && isBrowser) || isFirefox || isIE

	const upload = async () => {
		const _date = new Date(date).toISOString()

		let formData = new FormData()
		if (moment().isAfter(moment(date)) && !id) {
			useErrorStore.getState().update(new Error(t("date_is_past") as string))
		} else {
			const _schedule = [
				{ type: "presentation" },
				...schedule,
				{ type: "sendoff" },
			]
			if (csvFile) {
				formData.append("file", csvFile)
			}

			formData.append(
				"json",
				JSON.stringify({
					date: _date,
					schedule: _schedule,
					title,
					isEnglish,
				})
			)

			const endpoint = id ? `/webinars/${id}` : "/webinars/create"

			const success = await fetchApiAuthorized(
				endpoint,
				auth.token,
				auth.expiresIn,
				formData,
				id ? "PUT" : "POST"
			)

			if (success) {
				navigate("/moderator/webinar")
			}
		}
	}

	const check = () => {
		if (
			date.length === 0 ||
			schedule.length === 0 ||
			schedule.filter((v) => {
				if (["patient", "quiz", "media", "case"].includes(v.type as string)) {
					return v.data === undefined
				} else {
					return false
				}
			}).length > 0
		) {
			if (schedule.length === 0) {
				useErrorStore.getState().update(new Error("Keine Module ausgewählt"))
			}

			setDateBorder("border-s-red")

			const _missingData = schedule
				.filter((v) => {
					if (["patient", "quiz", "media", "case"].includes(v.type as string)) {
						return v.data === undefined
					} else {
						return false
					}
				})
				.map((v) => v.id)
			setMissingData(_missingData as number[])
		} else {
			upload()
			setDateBorder(undefined)
			setMissingData([])
		}
	}

	return (
		<>
			<DashboardHeader
				buttonOnClick={check}
				buttonTitle="Speichern"
				cancelButtonOnClick={() => navigate("/moderator/webinar/upcoming")}
				cancelButtonTitle="Abrechen"
				title="Practinar erstellen"
			/>
			<div className="flex flex-col px-5 py-3">
				<div className="flex flex-row items-center mb-2.5">
					<p>{t("date_time")}</p>
					{showOldPicker ? (
						<Datetime
							initialValue={new Date()}
							locale="de"
							value={moment(date)}
							onChange={(e) => setDate({ target: { value: e } })}
						/>
					) : (
						<Input
							className={`flex-1 ml-5 ${dateBorder}`}
							type="datetime-local"
							value={date}
							onChange={setDate}
						/>
					)}
				</div>

				<div className="flex flex-row items-baseline">
					<p className="w-16 mb-7">{t("title")}</p>
					<Input
						placeholder="optional"
						type="text"
						value={title}
						onChange={setTitle}
					/>
				</div>

				<div className="flex flex-row items-center mb-3">
					<p className="mr-5">Webinarsprache</p>

					<SegmentedControl
						options={[
							{ title: "English", value: true },
							{ title: "Deutsch", value: false },
						]}
						value={isEnglish}
						onChange={setIsEnglish}
					/>
				</div>

				<p className="mb-2.5" style={{ marginBottom: "10px" }}>
					{t("program")}
				</p>

				<>
					<DndContainer className="mb-5 !justify-start">
						<Number>1</Number>
						<p className="mr-2.5">Presentation</p>
					</DndContainer>
					<DndProvider backend={HTML5Backend}>
						{schedule
							.filter((v) => v.type !== "sendoff")
							.map((v, i) => {
								let content
								switch (v.type) {
									case "patient":
										content = (
											<ScheduleItemPatientView
												value={v.data as string}
												onChange={(v: any) => {
													setSchedule((schedule) => {
														schedule[i].data = v
														return [...schedule]
													})
												}}
											/>
										)
										break
									case "quiz":
										content = (
											<ScheduleItemQuizView
												value={v.data as string}
												onChange={(v: any) => {
													setSchedule((schedule) => {
														schedule[i].data = v
														return [...schedule]
													})
												}}
											/>
										)
										break
									case "media":
										content = (
											<ScheduleItemMediaView
												value={v.data as string}
												onChange={(v: any) => {
													setSchedule((schedule) => {
														schedule[i].data = v
														return [...schedule]
													})
												}}
											/>
										)
										break
									case "case":
										content = (
											<ScheduleItemCaseView
												value={v.data as string}
												onChange={(v: any) => {
													setSchedule((schedule) => {
														schedule[i].data = v
														return [...schedule]
													})
												}}
											/>
										)
										break

									default:
										break
								}

								return (
									<ScheduleItem
										content={content}
										data={v.data as string}
										deletable={true}
										id={v.id as string}
										index={i}
										key={v.id as number}
										movable={true}
										move={movePart}
										redBorder={missingData.includes(v.id as number)}
										text={formatName(v.type as string)}
										type={v.type as string}
										onDelete={() => {
											setSchedule((v) => v.filter((_, idx) => idx !== i))
										}}
									/>
								)
							})}
					</DndProvider>
					<AddSchedule
						onAdd={(data) => {
							setSchedule([...schedule, { id: nanoid(), type: data.type }])
						}}
					/>
					<DndContainer className="mt-5 mb-7 !justify-start">
						<Number>{schedule.length + 2}</Number>
						<p className="mr-2.5">Sendoff</p>
					</DndContainer>
				</>

				<p className="my-5 mr-2.5">
					{t("participants")} ({t("can_be_added_later")})
				</p>

				{participants && participants.length > 0 && (
					<DndContainer className="my-2.5 flex-col p-0 overflow-hidden">
						<div className="grid grid-cols-user gap-3 self-stretch items-center p-2">
							<h4>Titel</h4>
							<h4>Name</h4>
							<h4>Email</h4>
							<h4>Code</h4>
							<div />
						</div>
						<div className="bg-slate-200 h-px self-stretch" />
						{participants.map((v, i) => {
							const color =
								v.email === "test@sonofun.live" ? "text-s-blue" : "text-s-black"
							return (
								<div
									className={`${
										i % 2 === 0 ? "bg-white" : "bg-slate-50"
									} ${color} grid grid-cols-user gap-3 self-stretch items-center p-2`}
									key={v.code as string}
								>
									<p>{v.title as string}</p>
									<p>{v.name as string}</p>
									<p>{v.email as string}</p>
									<p>{v.code as string}</p>
									<Button
										theme={ButtonTheme.White}
										onClick={() =>
											fetchApiAuthorized(
												`webinars/${id}/email`,
												auth.token,
												auth.expiresIn,
												undefined,
												"POST"
											)
										}
									>
										{t("resend_invite")}
									</Button>
								</div>
							)
						})}
					</DndContainer>
				)}

				<div className="flex flex-row items-baseline relative">
					<Input
						accept="text/csv"
						type="file"
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							const { files } = e.target

							if (files && files.length > 0) {
								const file = files[0]

								setCsvFile(file)
							}
						}}
					/>

					<a
						href={
							currentWebinar && csvFile
								? formatUrl(`/${csvFile}`)
								: "/SonoFunLive_InviteUsers.xls"
						}
						style={{ position: "absolute", right: "25px", top: "16px" }}
					>
						{currentWebinar && participants
							? t("download_data")
							: t("download_form")}
					</a>
				</div>
			</div>
		</>
	)
}

export default CreateWebinar
